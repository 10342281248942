(function () {
    'use strict';

    angular.module('common')
        .component('aflInstructionSubtopics', {
            templateUrl: '/static/javascript/directives/afl-instruction-subtopics/afl-instruction-subtopics.html',
            controller: aflInstructionSubtopicsController,
            bindings: {
                topics: '<'
            }
        });

    function aflInstructionSubtopicsController() {

    }
})();
